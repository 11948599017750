@import "../../variable.scss";

.service-card-container {
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 12px;
  padding: 1.6rem;
  height: auto;

  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  .icon-container {
    border-radius: 12px;
    padding: 1rem;
    margin-right: auto;
    margin-bottom: 2.4rem;

    svg {
      font-size: 3.6rem;
    }
  }

  .card-text {
    .title {
      font-size: 2.4rem;
      font-weight: 600;
      margin-bottom: 2.4rem;
      display: block;
    }

    .description {
      font-size: 1.6rem;
      line-height: 3rem;
      color: $text-color-light;
    }

    margin-bottom: 3.6rem;
  }

  .button-container {
    // position: absolute;
    // bottom: 1.6rem;
    // left: 1.6rem;

    button {
      display: flex;
      gap: 0.4rem;
      border: none;
      padding: 1rem;
      font-size: 1.6rem;
      border-radius: 12px;
      align-items: center;
      background-color: $dark-green;
      color: $light-green;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
      cursor: pointer;

      svg {
        font-size: 3rem;
      }
    }
  }
}
