@import "../../variable.scss";

.laptop-card {
  padding: 2.4rem;
  .laptop-card-wrapper {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 12px;

    .laptop-card__img__container {
      background-color: $light-green;
      padding: 2.4rem;
      padding-bottom: 1.6rem;
      img {
        width: 54rem;
        height: 30rem;
        object-fit: contain;
        overflow: hidden;
      }
    }

    .content {
      padding: 2.4rem;
      .content-text {
        font-size: 1.6rem;
        color: $text-color-dark;
      }

      span {
        font-weight: 600;
        margin-bottom: 0.4rem;
        display: inline-block;
      }

      li {
        list-style: inside;
        // list-style-color: red;
        color: $text-color-light;
      }
    }
  }
}
