@import "../../variable.scss";

.services-container {
  .services-wrapper {
    max-width: 130rem;
    margin: 0 auto;
    .heading {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      margin-bottom: 4.8rem;
      .title {
        color: $dark-green;
        font-size: 1.6rem;
        text-transform: uppercase;
      }

      .subtitle {
        font-size: 3.6rem;
        font-weight: 600;
      }
    }

    .services-cards-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(100%, 250px), 1fr));
      gap: 3.6rem;
    }


    @media only screen and (max-width: 1200px){
   
    } 

    @media only screen and (max-width: 992px){

    }

    @media only screen and (max-width: 768px){

    }

    @media only sreen and (max-width: 576px){

    }

    @media only screen and (max-width: 480px){
      
    }
  }
}
