@import "../../variable.scss";

.footer-container{
  background-color: $dark-green;
  padding: 2.4rem;
  .footer-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $light-green;
    max-width: 130rem;
    margin: 0 auto;

    .copyright-section{
      font-size: 1.4rem;
    }

    .social-media-links{
      display: flex;
      align-items: center;
      gap: 3.6rem;

      a{
        color: $light-green;
      }
      svg{
        font-size: 2.4rem;
        cursor: pointer;
      }
    }
  }
}