@import "../../variable.scss";

.instructor-info {
  border-radius: 12px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  .instructor-info-img-container {
    padding: 1.6rem;
    background-color: $light-green;


    img {
      width: 20rem;
      height: 20rem;
      object-fit: cover;
      border: 2px solid $dark-green;
      border-radius: 50%;
      margin-bottom: 2.4rem;
    }

    .instructor-info-img-container__name {
      font-size: 1.8rem;
      font-weight: 600;
      color: $text-color-dark;
      margin-bottom: 0.4rem;
    }

    .instructor-info-img-container__role {
      font-size: 1.6rem;
      font-weight: 400;
      color: $text-color-light;
    }
  }

  .instructor-info-content {
    padding: 1.6rem;
    margin-bottom: 3.6rem;
    .instructor-info-content__description {
      font-size: 1.6rem;
      line-height: 3rem;
      color: $text-color-light;
    }
  }
}
