@import "../../variable.scss";

.testimonial-container {
  background-color: $light-green;
  padding: 4.8rem 0;

  .testimonial-wrapper {
    max-width: 130rem;
    margin: 0 auto;
    .heading-container {
      display: flex;
      gap: 3rem;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 4.8rem;
      .heading-text {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        .title {
          color: $dark-green;
          font-size: 1.6rem;
          text-transform: uppercase;
        }

        .subtitle {
          font-size: 3.6rem;
          font-weight: 600;
        }
      }

      .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.8rem;

        button {
          padding: 1rem;
          border-radius: 50%;
          border: none;
          background-color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.5s ease-in-out;

          svg {
            font-size: 3rem;
            color: $text-color-light;
            transition: all 0.5s ease-in-out;
          }

          &:hover {
            background-color: $dark-green;

            svg {
              color: $light-green;
            }
          }
        }
      }
    }
  }
}
