$dark-green: #48b774;
$light-green: #edf8f4;
$light-orange: #fff0e9;
$light-yellow: #fff6dd;
$white: #ffffff;
$background-color: #fcfdfd;
$accent-color-orange: #ff8345;
$accent-color-yellow: #ffca45;
$text-color-dark: #2e2e2e;
$text-color-light: #595959;
$hover-green: #159d80;