@import "../../variable.scss";

.store {
  .store-hero {
    padding: 4.8rem;
    background-color: $light-green;

    .store-hero__wrapper {
      max-width: 130rem;
      margin: 0 auto;
    }

    .store__content__container {
      .title {
        font-weight: 600;
        font-size: 4.4rem;
        color: $text-color-dark;
        margin-bottom: 2.4rem;

        span {
          color: $dark-green;
        }
      }

      @media only screen and (max-width: 992px) {
        .title {
          font-size: 3rem;
        }
      }

      .subtitle {
        font-size: 1.8rem;
        line-height: 3rem;
        margin-bottom: 4.8rem;
        color: $text-color-light;
      }

      button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        border: none;
        padding: 1.2rem;
        border-radius: 6px;
        font-size: 1.6rem;
        color: $white;
        background-color: #48b774;
        transition: all 0.5s ease-in-out;
        text-transform: none;
        font-family: inherit !important;
        font-weight: 400;

        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }

    .store__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media only screen and (max-width: 992px) {
      .store__img {
        width: 90%;
        height: 90%;
        object-fit: contain;
      }
    }

    @media only screen and (max-width: 768px) {
      .store__img {
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
    }
  }

  .store-content {
    .store-content__wrapper {
      max-width: 130rem;
      margin: 0 auto;
      .content-title {
        display: flex;
        justify-content: center;
        align-items: center;

        h2 {
          font-size: 3rem;
          font-weight: 600;
          padding-bottom: 1.6rem;
          border-bottom: 4px solid $accent-color-orange;
        }
      }

      .content-listing{
        margin-top: 4.8rem;
      }
    }
  }
}
