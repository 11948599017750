@tailwind base;
@tailwind components;
@tailwind utilities;


@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Poppins", sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  background-color: #fcfdfd;
  color: #2e2e2e;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

button {
  font-family: "Poppins", sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
}

/* Breakpoints - 480px, 576px, 768px, 992px, 1200px, 1600px */
@media only screen and (max-width: 1200px) {
  html {
    /* 9px/16px = 56.25 */
    font-size: 56.25%;
  }
}

@media only screen and (max-width: 992px) {
  html {
    /* 8px/16px = 50 */
    font-size: 50%;
  }
}