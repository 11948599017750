@import "../../variable.scss";

.why-us-container{
  .why-us-wrapper{
    max-width: 130rem;
    margin: 0 auto;
    .heading-container{
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      margin-bottom: 4.8rem;

      .title{
        font-size: 1.6rem;
        color: $dark-green;
        text-transform: capitalize;
      }

      .subtitle{
        font-size: 3.6rem;
        font-weight: 600;
      }
    }

    .features-container{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
      row-gap: 7.2rem;
      column-gap: 3.6rem;
    }
  }
}