@import "../../variable.scss";

.roadmap{
  border-radius: 12px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 2.4rem;
  margin: 0 2.4rem;
  // margin-bottom: 4.8rem;
  background-color: $light-green;
  

  h3{
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1.6rem;
    color: $text-color-dark;
    color: $dark-green;
  }

  p{
    font-size: 1.6rem;
    line-height: 3rem;
    color: $text-color-light;
  }
}