@import "../../variable.scss";

header {
  background-color: $light-green;
  padding: 0 4.8rem;
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 130rem;
    height: 8rem;
    margin: 0 auto;

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2.4rem;
      .logo-image {
        width: 4.4em;
        height: 4.4rem;
        object-fit: contain;
        cursor: pointer;
      }
      .logo-text {
        font-size: 2.4rem;
        font-weight: 600;
        color: $dark-green;
      }
    }

    .nav-container {
      .nav-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2.4rem;
        list-style: none;

        li {
          margin-bottom: auto;
          border-bottom: 4px solid transparent;
          transition: all 0.7 ease-in-out;
          a {
            font-size: 1.8rem;
            color: $text-color-dark;
            margin: 1rem 0;
            display: inline-block;
          }
        }

        .active {
          border-bottom: 4px solid $accent-color-orange;
          
        }
      }
    }

    .button-container {
      button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        text-transform: none;
        font-family: inherit !important;
        font-weight: 400;
        svg {
          height: 1.6rem;
          width: 1.6rem;
        }

        border: none;
        padding: 1rem;
        border-radius: 6px;
        font-size: 1.6rem;
        color: $light-green;
        background-color: $dark-green;
        transition: all 0.5s ease-in-out;
        
      }
    }

    .hamburger-menu-container{
      display: none;
      button{
        color: $dark-green !important;
        svg{
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }

  @media only screen and (max-width: 576px){
    .header-container{
      .nav-container{
        display: none;
      }

      .button-container{
        display: none;
      }

      .hamburger-menu-container{
        display: block;
      }
    }
  }
}
header.bg-active{
  background-color: rgba(255, 255, 255, 0.95);
}

