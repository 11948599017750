@import "../../variable.scss";

.mobile-nav {
  .active {
    .mobile-nav-text span{
      opacity: 1;
      font-weight: 500 !important;
    }

    .mobile-nav-icon{
      opacity: 1;
    }
  }
  .mobile-nav-icon {
    color: $dark-green;
    min-width: 0 !important;
    opacity: 0.7;
    svg {
      font-size: 1.8rem !important;
    }
  }
  .mobile-nav-text span {
    font-size: 1.6rem !important;
    font-weight: 400 !important;
    text-decoration: none !important;
    color: $text-color-dark;
    padding: 0.8rem;
    opacity: 0.7;
    font-family: inherit !important;
  }

  button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    text-transform: none;
    font-family: inherit !important;
    font-weight: 400;
    svg {
      height: 1.6rem;
      width: 1.6rem;
    }

    border: none;
    padding: 0.8rem;
    border-radius: 6px;
    font-size: 1.6rem;
    color: $light-green;
    background-color: $dark-green;
    transition: all 0.5s ease-in-out;
    
  }
}
