@import '../../variable.scss';

.services{
  .services-hero{
    background-color: $light-green;
    padding: 4.8rem;
    .services-hero__wrapper{
      max-width: 130rem;
      margin: 0 auto;

      .services-hero__content{
        .services-hero__title{
          font-weight: 600;
          font-size: 4.4rem;
          margin-bottom: 2.4rem;
          color: $text-color-dark;

          span{
            color: $dark-green;
          }
        }

        @media only screen and (max-width: 992px){
          .services-hero__title{
            font-size: 3rem;
          }
        }

        .services-hero__subtitle{
          font-size: 1.6rem;
          line-height: 3rem;
          color: $text-color-light;
          margin-bottom: 4.8rem;
        }

        button {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.8rem;
          border: none;
          padding: 1.2rem;
          border-radius: 6px;
          font-size: 1.6rem;
          color: $white;
          background-color: #48b774;
          transition: all 0.5s ease-in-out;
          text-transform: none;
          font-family: inherit !important;
          font-weight: 400;
  
          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
    }
  }

  .services-content{
    .services-content__wrapper{
      max-width: 130rem;
      margin: 0 auto;
      .services-content__title{
        font-size: 3rem;
        font-weight: 600;
        padding-bottom: 1.6rem;
        border-bottom: 4px solid $accent-color-orange;
        margin-bottom: 4.8rem;
      }
    }
  }


}