@import "../../variable.scss";

.service{
  margin-bottom: 4.8rem;
  .service-image-container{
    img{
      width: 85%;
      // height: 80%;
      aspect-ratio: 3 / 2;
      object-fit: contain;
    }
  }

  .service-content-container{
    .service-content-title{
      font-size: 3rem;
      font-weight: 600;
      margin-bottom: 2.4rem;
      color: $text-color-dark;
    }

    .service-content-description{
      color: $text-color-light;
      font-size: 1.6rem;
      line-height: 3rem;
    }
  }
}