@import "../../variable.scss";

.contact-form {
  input,
  textarea,
  button {
    padding: 1.6rem;
    border-radius: 12px;
    width: 100%;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      font-family: inherit !important;
      font-size: 1.4rem !important;
      color: $text-color-light;

    &:focus {
      outline: $light-green;
    }
  }

  button {
    margin-top: 2.4rem;
    cursor: pointer;
    color: $light-green;
    background-color: $dark-green;
    border-radius: 6px;
    border: none;
    border-radius: 6px;
    padding: 1.2rem;
    font-size: 1.6rem;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
