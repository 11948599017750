@import "../../variable.scss";

.course {
  .course-hero {
    background-color: $light-green;
    padding: 4.8rem;
    .course-hero__wrapper {
      max-width: 130rem;
      margin: 0 auto;

      .course-hero__title {
        font-size: 4.4rem;
        font-weight: 600;
        margin-bottom: 2.4rem;
        color: $text-color-dark;

        span {
          color: $dark-green;
        }
      }

      @media only screen and (max-width: 992px){
        .course-hero__title{
          font-size: 3rem;
        }
      }

      .course-hero__subtitle {
        font-size: 1.8rem;
        color: $text-color-light;
        margin-bottom: 4.8rem;
        line-height: 3rem;
      }

      button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.8rem;
        border: none;
        padding: 1.2rem;
        border-radius: 6px;
        font-size: 1.6rem;
        color: #ffffff;
        background-color: #48b774;
        transition: all 0.5s ease-in-out;
        text-transform: none;
        font-family: inherit !important;
        font-weight: 400;
      }
    }
  }

  .course-content {
    .course-content__wrapper {
      max-width: 130rem;
      margin: 0 auto;

      .course-content-instructor {
        margin-bottom: 9.4rem;
        .course-content-instructor__title {
          font-size: 3rem;
          font-weight: 600;
          color: $text-color-dark;
          padding-bottom: 1.6rem;
          border-bottom: 4px solid $accent-color-orange;
          margin-bottom: 4.8rem;
        }
      }

      .course-content-roadmap {
        margin-bottom: 4.8rem;
        .course-content-roadmap__title {
          font-size: 3rem;
          font-weight: 600;
          color: $text-color-dark;
          padding-bottom: 1.6rem;
          border-bottom: 4px solid $accent-color-orange;
          margin-bottom: 4.8rem;
        }

        .course-content-roadmap__block{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .vertical-line {
          width: 1px;
          height: 4.8rem;
          border-left: 4px dotted $accent-color-orange;
          margin: 0.8rem;
        }
      }
    }
  }
}
