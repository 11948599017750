@import "../../variable.scss";

.hero-container{
  background-color: $light-green;
  padding: 4.8rem 0 4.8rem 0;
  .hero-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 9.6rem;
    max-width: 130rem;
    margin: 0 auto;

    .hero-content{
      .hero-heading{
        font-weight: 600;
        font-size: 4.4rem;
        color: $text-color-dark;
        margin-bottom: 2.4rem;

        span{
          color: $dark-green;
        }
      }

      .hero-subheading{
        font-size: 1.8rem;
        line-height: 3rem;
        margin-bottom: 4.8rem;
        color: $text-color-light;
      }

      .button-container{
        display: flex;
        align-items: center;
        gap: 1.6rem;
        flex-wrap: wrap;
        button{
          cursor: pointer;
          color: $light-green;
          border-radius: 6px;
          border: none;
          border-radius: 6px;
          padding: 1.2rem;
          font-size: 1.6rem;

          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.4rem;
          svg{
            width: 1.6rem;
            height: 1.6rem;
          }
        }

        .--modifier-repair{
          background: linear-gradient(to right, $dark-green 50%, transparent 50%);
          background-size: 200% 100%;
          background-position: right bottom;
          outline: 2px solid $dark-green;
          transition: all .5s ease-out;
          color: $dark-green;

          &:hover{
            background-position: left bottom;
            color: $light-green;
          }
        }

        .--modifier-enroll{
          background: linear-gradient(to right, $dark-green 50%, transparent 50%);
          background-size: 210% 100%;
          background-position: left bottom;
          transition: all .5s ease-out;
          
          &:hover{
            outline: 2px solid $dark-green;
            background-position: right bottom;
            color: $dark-green;
          }
        }

      }

    }

    .hero-image{
      img{
        // width: 56rem;
        // height: 56rem;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .hero-wrapper{
      .hero-content{
        .hero-heading{
          font-size: 3rem;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .hero-wrapper{
      grid-template-columns: 1fr;

      .hero-content{
        .hero-heading{
          text-align: center;
        }

        .hero-subheading{
          text-align: center;
        }

        .button-container{
          justify-content: center;
        }
      }

      .hero-image{
        display: flex;
        justify-content: center;
      }
    }

  }

  @media only screen and (max-width: 768px) {
    
  }


}