@import "../../variable.scss";

.feature-container {
  list-style: none;
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
  gap: 2.4rem;

  .icon-container{
    svg{
      font-size: 4rem;
      color: $dark-green;
    }
  }

  .feature-content{

    .title{
      font-size: 2.4rem;
      font-weight: 600;
      color: $text-color-dark;
      margin-bottom: 1rem;
    }

    .description{
      font-size: 1.6rem;
      line-height: 3rem;
      color: $text-color-light;
    }
  }
}
