@import "../../variable.scss";

.slider-container{
  .slider-wrapper{
    background-color: $white;
    margin: 1.6rem;
    margin-bottom: 2rem;
    margin: 0 3.6rem 1.6rem 0;
    padding: 3rem;
    border-radius: 12px;
    // box-shadow: h-shadow v-shadow blur spread color inset;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    box-shadow: rgba(0, 0, 0, 0.1) 10px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

    .quote{
      svg{
        transform: rotate(180deg);
        color: $dark-green;
        font-size: 5.6rem;
        margin-bottom: 1rem;
        margin-left: -1rem;
      }
    }

    .review{
      font-size: 1.6rem;
      color: $text-color-light;
      line-height: 3rem;
      margin-bottom: 1.6rem;
    }

    .name{
      font-size: 1.6rem;
      font-weight: 600;
      color: $text-color-dark;
      margin-bottom: 1.6rem;
    }

    .rating{
      svg{
        font-size: 2.4rem;
        color: $accent-color-yellow;
      }
    }
  }  
}