@import "../../variable.scss";

.contact-container {
  .contact-wrapper {
    max-width: 130rem;
    margin: 0 auto;
    .heading-container {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      margin-bottom: 4.8rem;

      .title {
        font-size: 1.6rem;
        color: $dark-green;
        text-transform: uppercase;
      }

      .description {
        font-size: 3.6rem;
        font-weight: 600;
        color: $text-color-dark;
      }
    }

    .content-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      gap: 3rem;

      .contact-info-container {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;

        .address-container,
        .phone-container,
        .mail-container,
        .store-time {
          display: flex;
          flex-direction: column;
          gap: 0.8rem;

          svg {
            font-size: 3rem;
            color: $dark-green;
          }

          p {
            font-size: 1.6rem;
            line-height: 3rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .contact-wrapper {
      .content-container {
        grid-template-columns: 1fr;
      }
    }
  }
}
